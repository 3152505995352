<template>
    <div class="bottom-0 inset-x-0 sm:inset-0 sm:flex sm:items-center sm:justify-center h-full w-full">
        <div class="bg-gray-200 dark:bg-material-grey-darken-4
            rounded-lg
            px-4 pt-5 pb-4
            overflow-hidden
            shadow-xl dark:shadow-xl-grey
            transform transition-all sm:max-w-lg sm:w-full"
            role="dialog" aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="sm:flex sm:items-start">
                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-500 sm:mx-0 sm:h-10 sm:w-10">
                    <svg class="h-6 w-6 text-red-600 dark:text-red-800" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                    </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-400" id="modal-headline">
                        <slot name="title">{{header}}</slot>
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5 text-gray-500 dark:text-gray-300">
                            <slot></slot>
                        </p>
                    </div>
                </div>
            </div>

            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <v-btn
                    :loading="actionButtonIsLoading"
                    text
                    @click="$emit('alert-component:action')"
                    type="button"
                    class="inline-flex justify-center w-full
                        rounded-md border border-solid border-transparent px-4 py-2
                        bg-red-600 dark:bg-red-900 hover:bg-red-500 dark:hover:bg-red-800
                         text-base
                        text-white
                        shadow-sm
                        focus:outline-none
                        focus:border-red-700
                        focus:shadow-outline-red
                        transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  <slot name="actionButton"/>
                </v-btn>
              </span>
                <span class="flex w-full rounded-md shadow-sm sm:w-auto">
                    <v-btn
                        :disabled="actionButtonIsLoading"
                        text
                        @click="$emit('alert-component:cancel')"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md
                         px-4 py-2
                         border border-solid
                         border-gray-300 dark:border-gray-600
                         bg-gray-200 dark:bg-material-grey-darken-4
                         text-base leading-6 font-medium
                         text-gray-700 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200
                         shadow-sm  focus:outline-none
                         focus:border-blue-300 dark:focus:border-blue-600
                         focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      <slot name="cancelButton"/>
                    </v-btn>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            actionButtonIsLoading: {
                type: Boolean,
                default: false
            },
            header: {
                type: String,
            },
        },

        computed: {},
    };
</script>
