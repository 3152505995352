<template>
    <index route-name="pwatcher.wishlist.list.closed" type="closed"  :on-dashboard="onDashboard" :dashboard-full="dashboardFull" :dashboard-used-cols="dashboardUsedCols" />
</template>

<script>
    import Index from './Index';

    export default {
        components: {
            Index
        },

        props: {
            onDashboard: {
                type: Boolean,
                default: false
            },

            dashboardFull: {
                type: Boolean,
                default: false
            },

            dashboardUsedCols: {
                type: Number,
                default: 6
            },
        },

        watch: {},

        mounted() {

        },

        data: () => ({}),

        methods: {},

        computed: {
        },
    };
</script>
