<template>
    <div v-if="! useDropdown && (canChangeApi || showWithdraw || canChangeStatus || canChangePrio || canChangeOv || canChangeHd || canFollow)">
        <v-btn
            color="success"
            x-small
            class="mr-3"
            v-if="canChangeStatus"
            @click="$emit('pwatcher:wishlist:detail-actions:change-status')">
            {{ $t('Wishlist.detail.buttons.changeStatus') }}
        </v-btn>

        <v-menu v-model="visibleFlags" offset-y v-if="canChangePrio || canChangeOv || canChangeHd">
            <template v-slot:activator="{ on }">
                <v-btn
                    color="info"
                    x-small
                    class="mr-3"
                    :loading="changingFlags"
                    v-on="on"
                >
                    {{ $t('Wishlist.detail.buttons.changeFlags') }}
                    <v-icon x-small class="ml-1 transform transition duration-200" :class="{'rotate-180': visibleFlags}">$chevronDown</v-icon>
                    <v-icon x-small class="ml-1" v-if="changingFlags">$spinner</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item :disabled="changingFlags" v-if="canChangeHd" @click="$emit('pwatcher:wishlist:detail-actions:activate-hd')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.activateHd') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="changingFlags" v-if="canChangeOv" @click="$emit('pwatcher:wishlist:detail-actions:activate-ov')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.activateOv') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="changingFlags" v-if="canChangePrio" @click="$emit('pwatcher:wishlist:detail-actions:activate-prio')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.activatePrio') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-btn
            color="success"
            x-small
            class="mr-3"
            :loading="changingFollow"
            v-if="canFollow"
            @click="$emit('pwatcher:wishlist:detail-actions:toggle-follow')"
        >
            {{ isFollowing ? 'Abo beenden' : 'Abonnieren' }}
        </v-btn>

        <v-btn
            color="warning"
            x-small
            class="mr-3"
            v-if="canChangeApi"
            @click="$emit('pwatcher:wishlist:detail-actions:modify-api')"
        >
            {{ $t('Wishlist.detail.buttons.modifyApi') }}
        </v-btn>

        <v-btn
            color="error"
            x-small
            class="mr-3"
            v-if="showWithdraw"
            @click="$emit('pwatcher:wishlist:detail-actions:withdraw-wish')"
        >
            {{ $t('Wishlist.detail.buttons.withdraw') }}
        </v-btn>
    </div>
    <div v-else-if="useDropdown && (canChangeApi || canChangeStatus || showWithdraw || canChangePrio || canChangeOv || canChangeHd ||canFollow)">
        <v-menu v-model="visible" offset-y>
            <template v-slot:activator="{ on }">
                <v-btn
                    color="info"
                    x-small
                    v-on="on"
                >
                    {{ $t('Wishlist.detail.useAction') }}

                    <v-icon x-small class="ml-1 transform transition duration-200" :class="{'rotate-180': visible}">$chevronDown</v-icon>
                    <v-icon x-small class="ml-1" v-if="changingFlags">$spinner</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item  v-if="canChangeStatus" @click="$emit('pwatcher:wishlist:detail-actions:change-status')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.changeStatus') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="changingFlags" v-if="canChangeHd" @click="$emit('pwatcher:wishlist:detail-actions:activate-hd')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.activateHd') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="changingFlags" v-if="canChangeOv" @click="$emit('pwatcher:wishlist:detail-actions:activate-ov')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.activateOv') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="changingFlags" v-if="canChangePrio" @click="$emit('pwatcher:wishlist:detail-actions:activate-prio')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.activatePrio') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canChangeApi" @click="$emit('pwatcher:wishlist:detail-actions:toggle-follow')">
                    <v-list-item-title>
                        <v-icon x-small class="ml-1" v-if="changingFollow">$spinner</v-icon>
                        <template v-else>
                            {{ isFollowing ? 'Abo beenden' : 'Abonnieren' }}
                        </template>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canChangeApi" @click="$emit('pwatcher:wishlist:detail-actions:modify-api')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.modifyApi') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="showWithdraw" @click="$emit('pwatcher:wishlist:detail-actions:withdraw-wish')">
                    <v-list-item-title>{{ $t('Wishlist.detail.buttons.withdraw') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    export default {
        data: () => ({
            visible: false,
            visibleFlags: false,
        }),

        props: {
            useDropdown: {
                type:    Boolean,
                default: false,
            },

            changingFlags: {
                type:    Boolean,
                default: false,
            },

            canChangeStatus: {
                type:    Boolean,
                default: false,
            },

            canChangeHd: {
                type:    Boolean,
                default: false,
            },

            canChangePrio: {
                type:    Boolean,
                default: false,
            },

            canChangeOv: {
                type:    Boolean,
                default: false,
            },

            canChangeApi: {
                type:    Boolean,
                default: false,
            },

            showWithdraw: {
                type:    Boolean,
                default: false,
            },

            canFollow: {
                type:    Boolean,
                default: true,
            },

            isFollowing: {
                type:    Boolean,
                default: false,
            },

            changingFollow: {
                type:    Boolean,
                default: false,
            },
        },
    };
</script>
