const statuses = [
    {
        text: 'Neu',
        value: 'new'
    },
    {
        text: 'Akzeptiert',
        value: 'accepted'
    },
    {
        text: 'Offen',
        value: 'open'
    },
    {
        text: 'In Bearbeitung',
        value: 'in-progress'
    },
    {
        text: 'Zurückgestellt',
        value: 'postponed'
    },
    {
        text: 'Abgelehnt',
        value: 'rejected'
    },
    {
        text: 'Abgelehnt (Bereits vorhanden)',
        value: 'rejected-exists'
    },
    {
        text: 'Abgelehnt (Bereits gewünscht)',
        value: 'rejected-wished'
    },
    {
        text: 'Erfüllt',
        value: 'fulfilled'
    },
];

export {
    statuses
}
