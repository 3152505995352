<template>
    <div class="position-relative" :class="{'fill-height': onDashboard}">
        <template v-if="showCreate && ! creationThroughModal">
            <create no-redirect v-on:pwatcher:wishlist:wish-created="wishCreated" v-on:pwatcher:wishlist:create-cancel="wishCanceled"/>
        </template>
        <template v-else-if="showDetails && ! detailsTroughModal">
            <detail
                no-redirect
                :preloaded-wish="selectedWish"
                v-on:pwatcher:wishlist:details-cancel="wishCanceled"
                v-on:pwatcher:wishlist:detail:wish-updated="wishUpdated"
            />
        </template>
        <template v-else>
            <v-dialog
                v-if="creationThroughModal && showCreate"
                v-model="showCreate"
                :fullscreen="$breakpoint.smAndDown"
                max-width="95%"
                :style="{'min-height:': '90%'}"
                content-class="h-90%"
                persistent
            >
                <v-card class="min-h-full">
                    <v-toolbar class="mb-3 grey darken-4" dense>
                        <v-toolbar-title class="flex-self-center">{{$t('Wishlist.list.create')}}</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <create
                            no-shadow
                            no-redirect
                            v-on:pwatcher:wishlist:wish-created="wishCreated"
                            v-on:pwatcher:wishlist:create-cancel="wishCanceled"/>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog
                v-if="detailsTroughModal && showDetails"
                v-model="showDetails"
                :fullscreen="$breakpoint.smAndDown"
                max-width="95%"
                :style="{'min-height:': '90%'}"
                content-class="h-90%"
            >
                <v-card class="min-h-full">
                    <v-card-text>
                        <detail
                            no-redirect
                            :preloaded-wish="selectedWish"
                            v-on:pwatcher:wishlist:details-cancel="wishCanceled"
                            v-on:pwatcher:wishlist:detail:wish-updated="wishUpdated"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-btn color="primary" @click="createWish" small outlined>{{$t('Wishlist.list.create')}}</v-btn>

            <div v-if="loading && ! reloadingWishes" :class="{'h-90%': onDashboard}">
                <v-boilerplate-loading v-if="! onDashboard" type="list-item-three-line@6" class="w-full"/>
                <div class="h-90%" v-else>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </div>
            </div>
            <div v-else>
                <sticky-with-intersection v-if="wishesExist" :background-sticky-color-classes="stickyColorClasses" :disabled="! useSticky">
                    <v-row class="mb-4">
                        <v-col cols="12" sm="4" md="3" lg="3" :class="{'self-center': ! $breakpoint.xs, 'text-center': ! $breakpoint.xs}">
                            {{countText}}
                        </v-col>
                        <v-col cols="12" sm="8" md="5" offset-md="1" lg="6" offset-lg="0">
                            <v-text-field
                                v-model="query"
                                :hint="$t('Wishlist.list.searchFieldHint')"
                                :label="$t('Wishlist.list.searchField')"
                                clearable
                            />
                        </v-col>
                    </v-row>
                </sticky-with-intersection>

                <template v-if="reloadingWishes">
                    <div class="text-center">
                        <v-icon>$spinner</v-icon>
                        - {{$t('Wishlist.list.reloadingWishes')}}
                    </div>
                </template>
                <template v-else-if="wishesWithoutPossibleDuplicates.length > 0">
                    <card-list-row
                        v-on:wishes-list:wish-selected="selectWish"
                        v-on:pwatcher:wishlist:list:wish-updated="wishUpdatedList"
                        :wishes="wishesWithoutPossibleDuplicates"
                        :on-dashboard="onDashboard"
                        :dashboard-full="dashboardFull"
                        :dashboard-used-cols="dashboardUsedCols"
                    />

                    <div v-if="hasUnloadedPages">
                        <template v-if="useButtonForLoading">
                            <div class="text-center">
                                <v-btn @click="loadNextPage" color="info" :loading="loadingPage">{{$t('Wishlist.list.loadNextPage')}}</v-btn>
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="loadingPage" class="text-center">
                                <v-icon>$spinner</v-icon>
                                - {{$t('Wishlist.list.pageLoading')}}
                            </div>

                            <div v-else v-intersect="loadNextPage"></div>
                        </template>
                    </div>
                </template>
                <div v-else>
                    {{$t('Wishlist.list.noWishesAvailable')}}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import {Create, Detail}       from '../Config/Components';
    import CardListRow            from './Helper/Index/CardListRow';
    import {debounce}             from 'lodash';
    import StickyWithIntersection from '@symfia/library/Views/Helper/StickyWithIntersection';
    import {
        flagName as WishesListSearchAreaStickyName,
        defaultActive as WishesListSearchAreaStickyDefault,
    }                             from '../Config/Flags/WishesListSearchAreaSticky';

    import {
        flagName as WishesCardsFirstNextPageThroughButtonName,
        defaultActive as WishesCardsFirstNextPageThroughButtonDefault,
        settingFlagName as WishesCardsFirstNextPageThroughButtonSettingsName,
        settingDefaultActive as WishesCardsFirstNextPageThroughButtonSettingsDefault,
    }                                                                                           from '../Config/Flags/WishesCardsFirstNextPageThroughButton';
    import {flagName as WishesCreateAsModalName, defaultActive as WishesCreateAsModalDefault}   from '../Config/Flags/WishesCreateAsModal';
    import {flagName as WishesDetailsAsModalName, defaultActive as WishesDetailsAsModalDefault} from '../Config/Flags/WishesDetailsAsModal';

    export default {
        components: {
            CardListRow,
            Create,
            Detail,
            StickyWithIntersection,
        },

        props: {
            routeName: {
                type:     String,
                required: true,
            },

            type: {
                type:     String,
                required: true,
            },

            onDashboard: {
                type:    Boolean,
                default: false,
            },

            dashboardFull: {
                type: Boolean,
                default: false
            },

            dashboardUsedCols: {
                type: Number,
                default: 6
            },
        },

        watch: {
            query() {
                this.debounceSearch();
            },
        },

        data: () => ({
            showCreate:         false,
            selectedWish:       null,
            showDetails:        false,
            debounceSearch:     null,
            wishes:             [],
            actualPage:         {},
            query:              '',
            loading:            false,
            reloadingWishes:    false,
            loadingPage:        false,
            axiosToken:         null,
            hasSearchWithQuery: false,
            firstLoadTriggered: false,
            wishesExist:        false,
        }),

        mounted() {
            this.loadFirstPage(true, true);

            this.$echo.listen(
                'Wish',
                '.Plugins\\PWatcher\\Wishlist\\Events\\WishCreated',
                this.addWishToListIfNeeded,
                'pwatcher-wishlist-list-view-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );

            this.$echo.listen(
                'Wish',
                '.Plugins\\PWatcher\\Wishlist\\Events\\WishUpdated',
                this.addWishToListIfNeeded,
                'pwatcher-wishlist-list-view-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );

            this.debounceSearch = debounce(() => {
                if (this.loadingPage || this.reloadingWishes) {
                    this.cancelRequests();
                }

                this.$nextTick(() => {
                    this.loadFirstPage(false, false, true);
                });
            }, 350);
        },

        beforeDestroy() {
            this.cancelRequests();

            this.$echo.stopListening(
                'Wish',
                '.Plugins\\PWatcher\\Wishlist\\Events\\WishCreated',
                this.addWishToListIfNeeded,
                'pwatcher-wishlist-list-view-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );

            this.$echo.stopListening(
                'Wish',
                '.Plugins\\PWatcher\\Wishlist\\Events\\WishUpdated',
                this.addWishToListIfNeeded,
                'pwatcher-wishlist-list-view-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );
        },

        methods: {
            selectWish(wish) {
                this.selectedWish = wish;
                this.showDetails  = true;

                this.$tracker.trackEvent('Wünsche', 'Wunsch Details anzeigen' + (this.onDashboard === true ? ' (Dashboard) ' : ''), wish.name, wish.id);
            },

            wishCreated(wish) {
                this.showCreate = false;

                this.addWishToListIfNeeded(wish);

                this.$tracker.trackEvent('Wünsche', 'Neuen Wunsch erzeugt' + (this.onDashboard === true ? ' (Dashboard) ' : ''), wish.name, wish.id);
            },

            addWishToListIfNeeded(wish) {
                this.$logger.debug(
                    'Adding or Updating Wish through Create / Broadcast',
                    wish,
                    'Wishlist-List',
                );

                const existingWishIndex = this.searchWishIndex(wish.id);

                if (existingWishIndex !== -1) {
                    this.$logger.debug(
                        'Found existing wish',
                        existingWishIndex,
                        'Wishlist-List',
                    );
                }

                if (this.type === 'mine') {
                    if (typeof wish.user_id === 'undefined' || ! this.isActualUser(wish.user_id)) {
                        this.$logger.debug(
                            'Wish not addable (is not own wish)',
                            null,
                            'Wishlist-List',
                        );

                        if (existingWishIndex !== -1) {
                            this.$delete(this.wishes, existingWishIndex);

                            if (typeof this.actualPage === 'object' && typeof this.actualPage.total !== 'undefined') {
                                this.$logger.debug(
                                    'Modify total for actual page object',
                                    null,
                                    'Wishlist-List',
                                );
                                this.actualPage.total = parseInt(this.actualPage.total) - 1;
                            }

                            if (this.wishesWithoutPossibleDuplicates.length <= 0) {
                                this.wishesExist = false;
                            }

                            this.$logger.debug(
                                'delete existing wish',
                                null,
                                'Wishlist-List',
                            );
                        }

                        return;
                    }
                } else {
                    if (typeof wish.statusPreparedKey === 'undefined' || this.type !== wish.statusPreparedKey) {
                        this.$logger.debug(
                            'Wish not addable (other type)',
                            null,
                            'Wishlist-List',
                        );

                        if (existingWishIndex !== -1) {
                            this.$delete(this.wishes, existingWishIndex);

                            if (typeof this.actualPage === 'object' && typeof this.actualPage.total !== 'undefined') {
                                this.$logger.debug(
                                    'Modify total for actual page object',
                                    null,
                                    'Wishlist-List',
                                );
                                this.actualPage.total = parseInt(this.actualPage.total) - 1;
                            }

                            if (this.wishesWithoutPossibleDuplicates.length <= 0) {
                                this.wishesExist = false;
                            }

                            this.$logger.debug(
                                'delete existing wish',
                                null,
                                'Wishlist-List',
                            );
                        }

                        return;
                    }
                }

                if (existingWishIndex === -1) {
                    this.wishes.unshift(wish);

                    if (typeof this.actualPage === 'object' && typeof this.actualPage.total !== 'undefined') {
                        this.$logger.debug(
                            'Modify total for actual page object',
                            null,
                            'Wishlist-List',
                        );
                        this.actualPage.total = parseInt(this.actualPage.total) + 1;
                    }

                    this.wishesExist = true;

                    this.$logger.debug(
                        'New wish added',
                        wish,
                        'Wishlist-List',
                    );
                } else {
                    let permissionInfosIncluded = this.wishes[existingWishIndex].permissionInfosIncluded ? this.wishes[existingWishIndex].permissionInfosIncluded : false;
                    let allApiInfosIncluded     = this.wishes[existingWishIndex].allApiInfosIncluded ? this.wishes[existingWishIndex].allApiInfosIncluded : false;
                    let apiDetailsIncluded      = this.wishes[existingWishIndex].apiDetailsIncluded ? this.wishes[existingWishIndex].apiDetailsIncluded : false;

                    const combinedWish = {
                        ...this.wishes[existingWishIndex],
                        ...wish,
                    };

                    combinedWish.permissionInfosIncluded = (wish.permissionInfosIncluded === true ? true : permissionInfosIncluded);
                    combinedWish.allApiInfosIncluded     = (wish.allApiInfosIncluded === true ? true : allApiInfosIncluded);
                    combinedWish.apiDetailsIncluded      = (wish.apiDetailsIncluded === true ? true : apiDetailsIncluded);

                    this.$set(this.wishes, existingWishIndex, combinedWish);

                    this.$logger.debug(
                        'Existing wish updated',
                        combinedWish,
                        'Wishlist-List',
                    );
                }
            },

            searchWishIndex(wishId) {
                return this.wishes.findIndex((existingWish) => {
                    return existingWish && existingWish.id && existingWish.id === wishId;
                });
            },

            wishUpdated(wish) {
                this.addWishToListIfNeeded(wish);
            },

            wishUpdatedList(wish) {
                this.addWishToListIfNeeded(wish);
            },

            wishCanceled() {
                const wasCreate = this.showCreate;
                const wasDetails = this.showDetails;

                this.showCreate  = false;
                this.showDetails = false;
                this.selectedWish = null;

                if (wasCreate) {
                    this.$tracker.trackEvent('Wünsche', 'Neuer Wunsch abbrechen' + (this.onDashboard === true ? ' (Dashboard) ' : ''));
                }

                if (wasDetails) {
                    this.$tracker.trackEvent('Wünsche', 'Wunsch Details ausblenden' + (this.onDashboard === true ? ' (Dashboard) ' : ''));
                }
            },

            createWish() {
                this.showCreate = true;

                this.$tracker.trackEvent('Wünsche', 'Neuer Wunsch' + (this.onDashboard === true ? ' (Dashboard) ' : ''));
            },

            cancelRequests() {
                if (this.axiosToken) {
                    this.axiosToken.cancel();
                    this.axiosToken = null;

                    this.loadingPage     = false;
                    this.loading         = false;
                    this.reloadingWishes = false;
                }
            },

            loadFirstPage(setLoading, isInitialCall, isSearch) {
                if (this.loading || this.reloadingWishes) {
                    return;
                }

                this.cancelRequests();

                if (setLoading !== false) {
                    this.loading = true;
                } else {
                    this.reloadingWishes = true;
                }

                this.$store.commit('System/activateLoading');

                let parameters = {};

                if (this.query && this.query !== '') {
                    parameters.query        = this.query;
                    this.hasSearchWithQuery = true;
                } else {
                    this.hasSearchWithQuery = false;
                }

                this.firstLoadTriggered = false;
                this.wishes             = [];
                let route               = this.route(this.routeName, parameters);

                this.loadPage(route, false, isInitialCall, isSearch);
            },

            loadPage(route, setFirstLoad, isInitialCall, isSearch) {
                this.axiosToken = this.$axiosToken.source();

                this.$axios.get(route,
                    {
                        cancelToken: this.axiosToken.token,
                    },
                ).then((response) => {
                    this.$logger.debug('Load page for cards display', {
                        routeName: this.routeName,
                        route:     route,
                        type:      this.type,
                        data:      response.data,
                    }, 'Wishlist-List');

                    this.wishes = this.wishes.concat(response.data.data);

                    this.actualPage = response.data;

                    if (setFirstLoad === true) {
                        this.firstLoadTriggered = true;
                    }
                }).finally(() => {
                    this.loading         = false;
                    this.loadingPage     = false;
                    this.reloadingWishes = false;
                    this.axiosToken      = null;
                    this.$store.commit('System/deactivateLoading');

                    if (isInitialCall) {
                        this.wishesExist = this.wishes.length > 0;
                    }

                    if (isSearch) {
                        this.$tracker.trackSiteSearch(this.query, 'Wünsche', this.actualPage.total || 0);
                    }
                });
            },

            loadNextPage(entries, observer, isIntersecting) {
                if (this.loadingPage
                    || this.reloadingWishes
                    || ! this.actualPage
                    || typeof this.actualPage.next_page_url === 'undefined'
                    || this.actualPage.next_page_url === null) {
                    return;
                }

                if (! isIntersecting && ! this.useButtonForLoading) {
                    return;
                }

                this.cancelRequests();

                this.$logger.debug(
                    'Trigger next Page load',
                    this.actualPage,
                    'Wishlist-List',
                );

                this.loadingPage = true;
                this.loadPage(this.actualPage.next_page_url, true);
            },
        },

        computed: {
            useSticky() {
                return this.isFeatureFlagActive(WishesListSearchAreaStickyName, WishesListSearchAreaStickyDefault);
            },

            stickyColorClasses() {
                return this.$store.getters['ColorManager/getSystemAppBarColorForOtherElements'];
            },

            countText() {
                let total = this.wishesWithoutPossibleDuplicates.length;

                if (typeof this.actualPage === 'object' && typeof this.actualPage.total !== 'undefined') {
                    total = this.actualPage.total;
                }

                if (this.wishesWithoutPossibleDuplicates.length <= 0 || total <= 0) {
                    return '';
                }

                return this.$t('Wishlist.list.countInfo', {count: this.wishesWithoutPossibleDuplicates.length, complete: total});
            },

            useButtonForLoading() {
                if (this.alwaysUseButtonForNextPage === true) {
                    return true;
                }

                return this.firstNextPageWithButton === true && this.firstLoadTriggered !== true;
            },

            firstNextPageWithButton() {
                return this.isFeatureFlagActive(WishesCardsFirstNextPageThroughButtonName, WishesCardsFirstNextPageThroughButtonDefault);
            },

            alwaysUseButtonForNextPage() {
                return this.firstNextPageWithButton && this.getFeatureFlagSetting(
                    WishesCardsFirstNextPageThroughButtonName,
                    WishesCardsFirstNextPageThroughButtonSettingsName,
                    WishesCardsFirstNextPageThroughButtonSettingsDefault,
                ) === true;
            },

            creationThroughModal() {
                if (this.onDashboard) {
                    return true;
                }

                return this.isFeatureFlagActive(WishesCreateAsModalName, WishesCreateAsModalDefault);
            },

            detailsTroughModal() {
                if (this.onDashboard) {
                    return true;
                }

                return this.isFeatureFlagActive(WishesDetailsAsModalName, WishesDetailsAsModalDefault);
            },

            hasUnloadedPages() {
                return this.actualPage && typeof this.actualPage.next_page_url !== 'undefined' && this.actualPage.next_page_url !== null;
            },

            wishesWithoutPossibleDuplicates() {
                return this.wishes.filter((wish, index, self) => {
                    return index === self.findIndex((innerWish) => {
                        return innerWish.id === wish.id;
                    });
                });
            },
        },
    };
</script>
