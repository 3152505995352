<template>
        <div class="position-relative w-full flex cursor-pointer" @click="selectWish">
            <v-dialog
                :value="showWithdrawWishDialog || showChangeApiDialog || showChangeStatusDialog"
                :fullscreen="$breakpoint.smAndDown"
                max-width="95%"
                :style="{'min-height:': '100%'}"
                content-class="h-full !shadow-none"
                persistent
            >
                <alert-content-card
                    v-if="showWithdrawWishDialog"
                    :action-button-is-loading="withdrawsWish"
                    v-on:alert-component:cancel="showWithdrawWishDialog = false"
                    v-on:alert-component:action="withdrawWish">
                    <template slot="title">
                        {{ $t('Wishlist.detail.withdraw.title') }}
                    </template>

                    <template slot="actionButton">
                        {{ $t('Wishlist.detail.withdraw.withdraw') }}
                    </template>

                    <template slot="cancelButton">
                        {{ $t('Wishlist.detail.withdraw.cancel') }}
                    </template>

                    <span slot="default" v-html="$t('Wishlist.detail.withdraw.content', {wish: title})"/>
                </alert-content-card>
                <v-card flat v-else-if="showChangeApiDialog">
                    <v-toolbar class="grey darken-4 !shadow-none" dense>
                        <v-toolbar-title class="flex-self-center">
                            {{$t('Wishlist.detail.changeApi.title')}}
                        </v-toolbar-title>
                        <v-spacer/>
                        <v-btn @click="showChangeApiDialog = false" icon>
                            <v-icon>$close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text class="px-0">
                        <sticky-with-intersection>
                            <div class="w-full grey darken-4 shadow-vuetify-toolbar">
                                <v-row class="w-full px-6">
                                    <v-col cols="12" sm="6" md="5">
                                        <text-field-with-validation
                                            :rules="{}"
                                            :label="$t('Wishlist.create.form.name')"
                                            v-model="changeApi.name"
                                            prepend-icon="$formName"/>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="5">
                                        <year-picker-with-validation
                                            :label="$t('Wishlist.create.form.year')"
                                            v-model="changeApi.year"
                                            prepend-icon="$formYear"/>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="2" class="md:flex md:items-center">
                                        <v-btn color="success" @click="changeApiEntry" :loading="changesApi" small :disabled="changeApi.apiSelected === null || changeApi.detailsLoaded === false">
                                            {{$t('Wishlist.detail.changeApi.associate')}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </sticky-with-intersection>

                        <div class="px-6">
                            <media-search-core
                                ref="searchCore"
                                :year="changeApi.year"
                                :name="changeApi.name"
                                v-on:media-search:providers-loaded="loadedApiProviders"
                                v-on:media-search:result-selected="changeApi.apiSelected = $event"
                                v-on:media-search:selected-result-reset="changeApi.apiSelected = null"
                                v-on:media-search:selected-result-load-details:start="changeApi.detailsLoaded = false"
                                v-on:media-search:selected-result-load-details:finish="changeApi.detailsLoaded = true"
                                v-on:media-search:update-selected="changeApi.apiSelected = $event"
                            />
                        </div>
                    </v-card-text>

                    <v-divider/>

                    <v-card-actions>
                        <v-btn @click="showChangeApiDialog = false" outlined small>
                            {{ $t('Schließen') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card flat v-else-if="showChangeStatusDialog">
                    <v-toolbar class="grey darken-4 !shadow-none" dense>
                        <v-toolbar-title class="flex-self-center">
                            {{$t('Wishlist.detail.updateStatus.title')}}
                        </v-toolbar-title>
                        <v-spacer/>
                        <v-btn @click="showChangeStatusDialog = false" icon>
                            <v-icon>$close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-form ref="form" @submit.prevent="changeStatus">
                            <v-row class="w-full">
                                <v-col cols="12" sm="6">
                                    <v-select
                                        :items="statuses"
                                        :label="$t('Wishlist.detail.updateStatus.status')"
                                        v-model="statusUpdateData.status"/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <date-picker-with-validation
                                        :label="$t('Wishlist.detail.updateStatus.release')"
                                        v-model="statusUpdateData.release"
                                        no-title
                                        :min="minDate"
                                        prepend-icon="$formYear"/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <text-area-with-validation
                                        :label="$t('Wishlist.detail.updateStatus.information')"
                                        rows="1"
                                        v-model="statusUpdateData.information"
                                        auto-grow
                                        prepend-icon=""/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <text-area-with-validation
                                        :label="$t('Wishlist.detail.updateStatus.internal')"
                                        rows="1"
                                        v-model="statusUpdateData.internal"
                                        auto-grow
                                        prepend-icon=""/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <text-field-with-validation
                                        label="Link"
                                        v-model="statusUpdateData.link"
                                        prepend-icon=""/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <text-field-with-validation
                                        label="Link Titel"
                                        v-model="statusUpdateData.linkTitle"
                                        prepend-icon=""/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <text-field-with-validation
                                        label="Interner Link"
                                        v-model="statusUpdateData.internalLink"
                                        prepend-icon=""/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <text-field-with-validation
                                        label="Internet Link Titel"
                                        v-model="statusUpdateData.internalLinkTitle"
                                        prepend-icon=""/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-switch
                                        v-model="statusUpdateData.forceMail"
                                        color="success"
                                        :label="$t('Wishlist.detail.updateStatus.forceMail')"
                                        :hint="$t('Wishlist.detail.updateStatus.forceMailHint')"
                                        persistent-hint
                                    />
                                </v-col>

                                <v-col cols="12">
                                    <v-btn color="success" @click="changeStatus" :loading="changingStatus" small>
                                        {{$t('Wishlist.detail.updateStatus.save')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                    <v-divider/>

                    <v-card-actions>
                        <v-btn @click="showChangeStatusDialog = false" outlined small>
                            {{ $t('Schließen') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <media-card
                :eager-load-images="false"
                :allow-tooltips="false"
                :small-card="isLarge"
                :open-delay-hover="openDelayHover"
                :title-is-on-main="titleInline"
                :title-is-on-main-if-no-poster="titleInlineIfNoPoster"
                :button-on-mobile-for-hover="buttonOnMobileForHover"

                :poster="poster"
                :background="background"
                :banner="banner"

                :title="name"
                :small-title="name"
                :year="! this.onDashboard ? wish.apiYear : null"

                :sub-text="getStatus"
                :second-sub-text="user"

                :third-left-sub-text="ovText"
                :third-middle-sub-text="priorityText"
                :third-right-sub-text="hdText"

                :desc="wish.additionalInfos"

                :desc-hover="wish.apiStrippedDesc"
                :small-desc-hover="description"

                :small-debug-text-hover="usedBreakpoint"
                :left-sub-text-hover="wish.apiSourceName"
                :right-sub-text-hover="rightSubTextHover"

                :sub-text-hover="allGenres"
                :small-sub-text-hover="allGenres"
            >
            <template slot="actionButtons" v-if="showActions">
                <action-group
                    class="d-flex"
                    :class="{
                        'ml-1': $vuetify.breakpoint.smAndDown
                    }"
                    :can-change-hd="canChangeHd"
                    :can-change-ov="canChangeOv"
                    :can-change-prio="canChangePrio"
                    :can-change-status="canChangeStatus"
                    :can-change-api="canChangeApi"
                    :show-withdraw="canWithdraw"
                    :changing-flags="changingFlags"
                    :can-follow="canFollow"
                    :is-following="wish.isFollowing"
                    :changing-follow="changeFollow"
                    use-dropdown
                    v-on:pwatcher:wishlist:detail-actions:withdraw-wish="showWithdrawWishDialog = true"
                    v-on:pwatcher:wishlist:detail-actions:modify-api="showChangeApiDialog = true"
                    v-on:pwatcher:wishlist:detail-actions:change-status="showChangeStatusDialog = true"
                    v-on:pwatcher:wishlist:detail-actions:activate-hd="activateHdFlag"
                    v-on:pwatcher:wishlist:detail-actions:activate-ov="activateOvFlag"
                    v-on:pwatcher:wishlist:detail-actions:activate-prio="activatePriorityFlag"
                    v-on:pwatcher:wishlist:detail-actions:toggle-follow="toggleFollow"
                />
            </template>
            </media-card>
        </div>
</template>

<script>
import MediaCard                from '@symfia/library/Views/Cards/Media/MediaCard';
import prune                    from 'underscore.string/prune';
import ActionGroup              from '../Detail/ActionGroup';
import AlertContentCard         from '@symfia/library/Views/Components/TailwindUi/AlertContentCard';
import MediaSearchCore          from '@symfia/media/views/MediaSearch/MediaSearchCore';
import TextFieldWithValidation  from '@symfia/core/components/VeeValidate/TextFieldWithValidation';
import YearPickerWithValidation from '@symfia/core/components/VeeValidate/YearPickerWithValidation';
import TextAreaWithValidation   from '@symfia/core/components/VeeValidate/TextAreaWithValidation';
import DatePickerWithValidation from '@symfia/core/components/VeeValidate/DatePickerWithValidation';
import {statuses}               from '../../../Config/Status';

export default {
        components: {
            MediaCard,
            ActionGroup,
            AlertContentCard,
            MediaSearchCore,
            TextFieldWithValidation,
            YearPickerWithValidation,
            TextAreaWithValidation,
            DatePickerWithValidation,
        },

        props: {
            wish: {
                type:     Object,
                required: true,
            },

            isLarge: {
                type:    Boolean,
                default: false,
            },

            titleInline: {
                type:    Boolean,
                default: false,
            },

            titleInlineIfNoPoster: {
                type:    Boolean,
                default: true,
            },

            openDelayHover: {
                type:    Number,
                default: null,
            },

            buttonOnMobileForHover: {
                type:    Boolean,
                default: true,
            },

            onDashboard: {
                type: Boolean,
                default: false
            },

            dashboardFull: {
                type: Boolean,
                default: false
            },

            dashboardUsedCols: {
                type: Number,
                default: 6
            },
        },

        mounted() {
            this.changeApi.name = this.wish.name ?? '';
            this.changeApi.year = this.wish.year ?? '';

            this.statusUpdateData.status            = this.wish.status;
            this.statusUpdateData.release           = this.wish.release ? this.wish.release : '';
            this.statusUpdateData.information       = this.wish.information;
            this.statusUpdateData.internal          = this.wish.internalInfos;
            this.statusUpdateData.link              = this.wish.linkUrl;
            this.statusUpdateData.linkTitle         = this.wish.linkTitle;
            this.statusUpdateData.internalLink      = this.wish.internalLinkUrl;
            this.statusUpdateData.internalLinkTitle = this.wish.internalLinkTitle;
        },

        watch: {
            'wish.status': function (newValue) {
                this.statusUpdateData.status = newValue;
            },

            'wish.release': function (newValue) {
                this.statusUpdateData.release = newValue ? newValue : '';
            },

            'wish.information': function (newValue) {
                this.statusUpdateData.information = newValue;
            },

            'wish.internalInfos': function (newValue) {
                this.statusUpdateData.internal = newValue;
            },

            'wish.linkUrl': function (newValue) {
                this.statusUpdateData.link = newValue;
            },

            'wish.linkTitle': function (newValue) {
                this.statusUpdateData.linkTitle = newValue;
            },

            'wish.internalLinkUrl': function (newValue) {
                this.statusUpdateData.internalLink = newValue;
            },

            'wish.internalLinkTitle': function (newValue) {
                this.statusUpdateData.internalLinkTitle = newValue;
            },
        },

        data: () => ({
            showWithdrawWishDialog: false,
            showChangeApiDialog:    false,
            withdrawsWish:          false,
            changingFlags:          false,
            changeFollow:           false,
            changesApi:             false,
            changeApi:              {
                name:          '',
                year:          '',
                apiSelected:   null,
                detailsLoaded: true,
            },
            showChangeStatusDialog: false,
            changingStatus:         false,
            statusUpdateData:       {
                status:            '',
                information:       '',
                internal:          '',
                link:              '',
                linkTitle:         '',
                internalLink:      '',
                internalLinkTitle: '',
                release:           '',
                forceMail:         false,
            },
            requests:               {
                withdraw: null,
                api:      null,
                flags:    null,
                follow:   null,
                status:   null,
            },
        }),

        beforeDestroy() {
            this.abortRequests();
        },

        methods: {
            abortRequests() {
                if (this.requests.withdraw) {
                    this.requests.withdraw.cancel();
                    this.requests.withdraw = null;
                }

                if (this.requests.api) {
                    this.requests.api.cancel();
                    this.requests.api = null;
                }

                if (this.requests.flags) {
                    this.requests.flags.cancel();
                    this.requests.flags = null;
                }

                if (this.requests.follow) {
                    this.requests.follow.cancel();
                    this.requests.follow = null;
                }

                if (this.requests.status) {
                    this.requests.status.cancel();
                    this.requests.status = null;
                }
            },

            loadedApiProviders() {
                this.$nextTick(() => {
                    window.setTimeout(() => {
                        this.$refs.searchCore.triggerSearch();
                    }, 250);
                });
            },

            changeStatus() {
                if (this.changingStatus) {
                    return;
                }

                this.changingStatus  = true;
                this.requests.status = this.$axiosToken.source();

                this.$axios.put(
                    this.route('pwatcher.wishlist.change-status', {wish: this.wish.id}),
                    this.statusUpdateData,
                    {
                        cancelToken: this.requests.status.token,
                    },
                ).then((response) => {
                    this.statusUpdateData.forceMail = false;

                    this.$emit('pwatcher:wishlist:list:wish-status-changed', response.data);
                    this.$emit('pwatcher:wishlist:list:wish-updated', response.data);
                }).finally(() => {
                    this.changingStatus         = false;
                    this.showChangeStatusDialog = false;
                    this.requests.status        = null;
                });
            },

            withdrawWish() {
                if (this.withdrawsWish) {
                    return;
                }

                this.withdrawsWish     = true;
                this.requests.withdraw = this.$axiosToken.source();

                this.$axios.post(
                    this.route('pwatcher.wishlist.withdraw', {wish: this.wish.id}),
                    null,
                    {
                        cancelToken: this.requests.withdraw.token,
                    },
                ).then((response) => {
                    this.$emit('pwatcher:wishlist:list:wish-withdrawn', response.data);
                    this.$emit('pwatcher:wishlist:list:wish-updated', response.data);

                    /*this.$notifications.notify({
                        messageKey: 'Wishlist.detail.notifications.withdrawn',
                        type:       'success',
                    }, this);*/
                }).finally(() => {
                    this.withdrawsWish          = false;
                    this.showWithdrawWishDialog = false;
                    this.requests.withdraw      = null;
                });
            },

            activateHdFlag() {
                this.changeFlags(['hd']);
            },

            activateOvFlag() {
                this.changeFlags(['ov']);
            },

            activatePriorityFlag() {
                this.changeFlags(['priority']);
            },

            changeFlags(flags) {
                if (this.changingFlags || ! Array.isArray(flags)) {
                    return;
                }

                this.changingFlags  = true;
                this.requests.flags = this.$axiosToken.source();

                this.$axios.put(
                    this.route('pwatcher.wishlist.change-flags', {wish: this.wish.id}),
                    {
                        flags: flags,
                    },
                    {
                        cancelToken: this.requests.flags.token,
                    },
                ).then((response) => {
                    this.$emit('pwatcher:wishlist:list:wish-flags-changed', response.data);
                    this.$emit('pwatcher:wishlist:list:wish-updated', response.data);
                }).finally(() => {
                    this.changingFlags  = false;
                    this.requests.flags = null;
                });
            },

            toggleFollow() {
                if (this.changeFollow) {
                    return;
                }

                this.changeFollow  = true;
                this.requests.follow = this.$axiosToken.source();

                let route, wasFollowing = false;

                if (this.wish.isFollowing) {
                    route = 'pwatcher.wishlist.unfollow';
                    wasFollowing = true;
                } else {
                    route = 'pwatcher.wishlist.follow';
                    wasFollowing = false;
                }

                this.$axios.put(
                    this.route(
                        route,
                        {
                            wish: this.wish.id
                        }
                    ),
                    {},
                    {
                        cancelToken: this.requests.follow.token,
                    },
                ).then((response) => {
                    this.$emit('pwatcher:wishlist:list:wish-follow-changed', response.data);
                    this.$emit('pwatcher:wishlist:list:wish-updated', response.data);

                    if (wasFollowing) {
                        this.$tracker.trackEvent('Wünsche', 'Wunsch nicht mehr folgen - Liste' + (this.onDashboard === true ? ' (Dashboard) ' : ''), this.wish.name, this.wish.id);
                    } else {
                        this.$tracker.trackEvent('Wünsche', 'Wunsch folgen - Liste' + (this.onDashboard === true ? ' (Dashboard) ' : ''), this.wish.name, this.wish.id);
                    }
                }).finally(() => {
                    this.changeFollow  = false;
                    this.requests.follow = null;
                });
            },

            changeApiEntry() {
                if (this.changesApi) {
                    return;
                }

                this.changesApi   = true;
                this.requests.api = this.$axiosToken.source();

                let apiInfos      = {...this.changeApi.apiSelected};
                let detailInfos   = null;
                let originalInfos = null;

                if (typeof apiInfos.details !== 'undefined') {
                    detailInfos = apiInfos.details;
                    delete apiInfos.details;
                }

                if (typeof apiInfos.apiData !== 'undefined') {
                    originalInfos = apiInfos.apiData;
                    delete apiInfos.apiData;
                }

                this.$axios.post(
                    this.route('pwatcher.wishlist.update-api', {wish: this.wish.id}),
                    {
                        apiInfos:         apiInfos,
                        apiDetailInfos:   detailInfos,
                        apiOriginalInfos: originalInfos,
                    },
                    {
                        cancelToken: this.requests.api.token,
                    },
                ).then((response) => {
                    this.$emit('pwatcher:wishlist:list:wish-api-changed', response.data);
                    this.$emit('pwatcher:wishlist:list:wish-updated', response.data);
                }).finally(() => {
                    this.changesApi          = false;
                    this.showChangeApiDialog = false;
                    this.requests.api        = null;
                });
            },

            selectWish() {
                this.$emit('wishes-list:wish-selected', this.wish);
            },
        },

        computed: {
            statuses() {
                return statuses;
            },

            minDate() {
                return this.$date().add(1, 'day').format(this.$t('dates.database_date'));
            },

            canWithdraw() {
                return this.wish.owns === true && this.isActualUser(this.wish.user_id) && this.wish.statusPreparedKey !== 'closed';
            },

            canFollow() {
                return ! (this.wish.owns === true && this.isActualUser(this.wish.user_id) && this.wish.statusPreparedKey !== 'closed');
            },

            canChangeApi() {
                return (this.wish.canModerate === true) || (this.wish.statusPreparedKey !== 'closed' && this.wish.canModify === true);
            },

            canChangeHd() {
                return this.wish.statusPreparedKey !== 'closed' && this.wish.hd !== true;
            },

            canChangePrio() {
                return this.wish.statusPreparedKey !== 'closed' && this.wish.priority !== true;
            },

            canChangeOv() {
                return this.wish.statusPreparedKey !== 'closed' && this.wish.ov !== true;
            },

            canChangeStatus() {
                return this.wish.canModerate === true;
            },

            showActions() {
                return this.canChangeApi || this.canChangeHd || this.canChangePrio || this.canChangeOv || this.canChangeStatus || this.canWithdraw;
            },

            title() {
                let title = this.wish.name;

                if (this.apiExist && title !== this.wish.apiName && this.$breakpoint.mdAndUp) {
                    if (! title || title === '') {
                        title = this.wish.apiName;
                    } else {
                        title += ' (' + this.wish.apiName + ')';
                    }
                }

                if (this.year && this.year !== '') {
                    title += ' (' + this.year + ')';
                }

                return title;
            },

            year() {
                let year = '';

                if (this.wish.year && this.wish.year !== '' && this.wish.year > 0) {
                    year = this.wish.year.toString();
                }

                if (this.wish.apiYear && this.wish.apiYear !== '' && this.wish.apiYear > 0 && this.wish.apiYear.toString() !== year && this.$breakpoint.mdAndUp) {
                    if (year !== '') {
                        year += ' / ';
                    }

                    year += this.wish.apiYear.toString();
                }

                return year;
            },

            rightSubTextHover() {
                let name = '';

                if (this.wish.apiTypeName && this.wish.apiTypeName !== '') {
                    name += this.wish.apiTypeName;
                }

                if (this.wish.apiSubTypeName && this.wish.apiSubTypeName !== '') {
                    if (name !== '') {
                        name += ' / ';
                    }

                    name += this.wish.apiSubTypeName;
                }

                return name === '' ? null : name;
            },

            poster() {
                if (typeof this.wish.localPosterPath !== 'undefined' && this.wish.localPosterPath !== null && this.wish.localPosterPath !== '') {
                    return this.wish.localPosterPath;
                }

                if (typeof this.wish.apiPosterPath !== 'undefined' && this.wish.apiPosterPath !== null && this.wish.apiPosterPath !== '') {
                    return this.wish.apiPosterPath;
                }

                return null;
            },

            banner() {
                if (typeof this.wish.localBannerPath !== 'undefined' && this.wish.localBannerPath !== null && this.wish.localBannerPath !== '') {
                    return this.wish.localBannerPath;
                }

                if (typeof this.wish.apiBannerPath !== 'undefined' && this.wish.apiBannerPath !== null && this.wish.apiBannerPath !== '') {
                    return this.wish.apiBannerPath;
                }

                return null;
            },

            background() {
                if (typeof this.wish.localBackgroundPath !== 'undefined' && this.wish.localBackgroundPath !== null && this.wish.localBackgroundPath !== '') {
                    return this.wish.localBackgroundPath;
                }

                if (typeof this.wish.apiBackgroundPath !== 'undefined' && this.wish.apiBackgroundPath !== null && this.wish.apiBackgroundPath !== '') {
                    return this.wish.apiBackgroundPath;
                }

                return null;
            },

            genres() {
                if (typeof this.wish.apiGenres !== 'object' || ! Array.isArray(this.wish.apiGenres)) {
                    return null;
                }

                return this.wish.apiGenres.slice(0, this.getSliceForGenres).join(', ');
            },

            allGenres() {
                if (typeof this.wish.apiGenres !== 'object' || ! Array.isArray(this.wish.apiGenres)) {
                    return null;
                }

                return this.wish.apiGenres.join(', ');
            },

            getSliceForGenres() {
                let length   = 3;
                let hasValueFor = false;

                if (this.isSmartphonePortrait() && ! hasValueFor) {
                    length   = 2;
                    hasValueFor = true;
                }

                if (this.isSmartphoneLandscape() && ! hasValueFor) {
                    length   = 5;
                    hasValueFor = true;
                }

                if (this.isLarge && ! this.isSmartphonePortrait()) {
                    length -= 1;
                }

                if (this.isLarge && this.isSmartphoneLandscape()) {
                    length -= 2;
                }

                return length;
            },

            canShowDebugInfos() {
                return this.isDebugEnabled() === true && this.isLocalEnv() === true;
            },

            usedBreakpoint() {
                if (this.canShowDebugInfos) {
                    let shouldBeTwoLine = this.shouldBeTwoLineName;
                    let hasValueFor        = false;
                    let value           = 1;

                    if (! this.poster && ! hasValueFor) {
                        value    = 2;
                        hasValueFor = true;
                    }

                    if (this.isSmartphonePortrait() && ! hasValueFor) {
                        value    = 3;
                        hasValueFor = true;
                    }

                    if (this.isSmartphoneLandscape() && ! hasValueFor) {
                        value    = 4;
                        hasValueFor = true;
                    }

                    if (this.isSmallLaptopScreen() && ! hasValueFor) {
                        value    = 5;
                        hasValueFor = true;
                    }

                    if (! this.isLargeLaptopScreen() && ! hasValueFor) {
                        value    = 6;
                        hasValueFor = true;
                    }

                    if (this.isXlBreakpoint() && ! hasValueFor) {
                        value    = 7;
                        hasValueFor = true;
                    }

                    let debugData = [];

                    if (value && typeof value.toString !== 'undefined') {
                        debugData.push(
                            'Breakpoint: ' + value.toString(),
                        );
                    }

                    if (this.nameLength && typeof this.nameLength.toString !== 'undefined') {
                        debugData.push(
                            'Name Length: ' + this.nameLength.toString(),
                        );
                    }

                    if (this.nameLengthWithCalc && typeof this.nameLengthWithCalc.toString !== 'undefined') {
                        debugData.push(
                            'Name Length + Calc: ' + this.nameLengthWithCalc.toString(),
                        );
                    }

                    if (this.shouldBeTwoLineLength && typeof this.shouldBeTwoLineLength.toString !== 'undefined') {
                        debugData.push(
                            '2 Line Check Length: ' + this.shouldBeTwoLineLength.toString(),
                        );
                    }

                    if (this.shouldBeTwoLineLengthCalc && typeof this.shouldBeTwoLineLengthCalc.toString !== 'undefined') {
                        debugData.push(
                            '2 Line Check Length + Calc: ' + this.shouldBeTwoLineLengthCalc.toString(),
                        );
                    }

                    if (this.getDescLength && typeof this.getDescLength.toString !== 'undefined') {
                        debugData.push(
                            'Desc Length: ' + this.getDescLength.toString(),
                        );
                    }

                    if (this.getDescLengthCalculation && typeof this.getDescLengthCalculation.toString !== 'undefined') {
                        debugData.push(
                            'Desc Length + Calc: ' + this.getDescLengthCalculation.toString(),
                        );
                    }

                    if (this.getSliceForGenres && typeof this.getSliceForGenres.toString !== 'undefined') {
                        debugData.push(
                            'Genre Slice: ' + this.getSliceForGenres.toString(),
                        );
                    }

                    debugData.push(
                        shouldBeTwoLine ? ' 2 Line Name' : ' 1 Line Name',
                    );

                    debugData.push(
                        this.isLarge ? ' Large' : ' Normal',
                    );

                    return debugData.join('<br>');
                }

                return null;
            },

            shouldBeTwoLineLength() {
                let hasValueFor   = false;
                let textLength = 28;

                if (! this.poster && ! hasValueFor) {
                    textLength = 50;
                    hasValueFor = true;
                }

                if (this.isSmartphonePortrait() && ! hasValueFor) {
                    textLength = 23;
                    hasValueFor = true;
                }

                if (this.isSmartphoneLandscape() && ! hasValueFor) {
                    textLength = 60;
                    hasValueFor = true;
                }

                if (this.isSmallLaptopScreen() && ! hasValueFor) {
                    textLength = 27;
                    hasValueFor = true;
                }

                if (! this.isLargeLaptopScreen() && ! hasValueFor) {
                    textLength = 23;
                    hasValueFor = true;
                }

                if (this.isXlBreakpoint() && ! hasValueFor) {
                    textLength = 43;
                    hasValueFor = true;
                }

                return textLength;
            },

            shouldBeTwoLineLengthCalc() {
                let textLength = this.shouldBeTwoLineLength;

                if (this.isLarge) {
                    textLength -= 9;

                    if (this.isSmartphoneLandscape()) {
                        textLength -= 30;
                    }

                    if (this.is4kResolution()) {
                        textLength -= 13;
                    } else if (this.isMdOrHigherBreakpoint() && ! this.isLargeLaptopScreen()) {
                        textLength -= 3;
                    }
                }

                return textLength;
            },

            shouldBeTwoLineName() {
                return false;
                return this.name.toString().length >= this.shouldBeTwoLineLengthCalc;
            },

            getDescLength() {
                let length       = 135;
                let newLengthSet = false;

                if (! this.poster && ! newLengthSet) {
                    length       = 220;
                    newLengthSet = true;
                }

                if (this.isSmartphonePortrait() && ! newLengthSet) {
                    length       = 105;
                    newLengthSet = true;
                }

                if (this.isSmartphoneLandscape() && ! newLengthSet) {
                    length       = 235;
                    newLengthSet = true;
                }

                if (this.isSmallLaptopScreen() && ! newLengthSet) {
                    length       = 150;
                    newLengthSet = true;
                }

                if (! this.isLargeLaptopScreen() && ! newLengthSet) {
                    length       = 80;
                    newLengthSet = true;
                }

                if (this.isXlBreakpoint() && ! newLengthSet) {
                    length       = 160;
                    newLengthSet = true;
                }

                return length;
            },

            getDescLengthCalculation() {
                let length = this.getDescLength;

                if (! this.shouldBeTwoLineName) {
                    length += 40;

                    if (this.isSmartphoneLandscape()) {
                        length += 25;
                    }
                }

                if (! this.genres && ! this.canShowDebugInfos) {
                    length += 30;
                }

                if (this.user) {
                    //length -= 30;
                }

                if (this.titleInline
                    || (
                        (! this.wish.poster || ! this.wish.poster === '')
                        && this.titleInlineIfNoPoster
                    )
                    || this.buttonOnMobileForHover
                ) {
                    length -= 40;
                }

                if (this.isLarge && ! this.isSmartphonePortrait()) {
                    length -= 55;

                    if (! this.shouldBeTwoLineName && this.isSmartphoneLandscape()) {
                        length -= 105;
                    } else if (this.isSmartphoneLandscape()) {
                        length -= 70;
                    }

                    if (this.is4kResolution()) {
                        length -= 5;
                    } else if (! this.shouldBeTwoLineName && this.isSmallLaptopScreen()) {
                        length -= 5;
                    } else if (this.isSmallLaptopScreen()) {
                        length += 0;
                    } else if (! this.shouldBeTwoLineName && ! this.isLargeLaptopScreen()) {
                        length += 40;
                    } else if (! this.isLargeLaptopScreen()) {
                        length += 60;
                    } else if (! this.shouldBeTwoLineName && this.isLargeLaptopScreen()) {
                        length += 5;
                    } else if (this.isLargeLaptopScreen()) {
                        length += 15;
                    }
                }
                if (this.onDashboard && ! this.dashboardFull) {
                    length -= 40;
                }

                return length;
            },

            description() {
                return prune(this.wish.apiStrippedDesc, this.getDescLengthCalculation);
            },

            user() {
                if (this.wish.user.username) {
                    if (this.isActualUser(this.wish.user)) {
                        return this.$t('Wishlist.list.from.same');
                    }

                    return this.$t('Wishlist.list.from.other', {user: this.generateUserName(this.wish.user.username)});
                }

                return null;
            },

            getStatus() {
                if (this.wish.statusName && this.wish.statusName !== '') {
                    return this.$t('Wishlist.list.status', {status: this.wish.statusName});
                }

                return null;
            },

            ovText() {
                if (this.wish.ov && this.wish.ov === true) {
                    return this.$t('Wishlist.list.ov');
                }

                return null;
            },

            priorityText() {
                if (this.wish.priority && this.wish.priority === true) {
                    return this.$t('Wishlist.list.prio');
                }

                return null;
            },

            hdText() {
                if (this.wish.hd && this.wish.hd === true) {
                    return this.$t('Wishlist.list.hd');
                }

                return null;
            },

            nameLength() {
                let length       = 65;
                let newLengthSet = false;

                if (! this.poster && ! newLengthSet) {
                    length       = null;
                    newLengthSet = true;
                }

                if (this.isSmartphonePortrait() && ! newLengthSet) {
                    length       = 40;
                    newLengthSet = true;
                }

                if (this.isSmartphoneLandscape() && ! newLengthSet) {
                    length       = 100;
                    newLengthSet = true;
                }

                if (this.isSmallLaptopScreen() && ! newLengthSet) {
                    length       = 70;
                    newLengthSet = true;
                }

                if (! this.isLargeLaptopScreen() && ! newLengthSet) {
                    length       = 40;
                    newLengthSet = true;
                }

                if (this.isXlBreakpoint() && ! newLengthSet) {
                    length       = 80;
                    newLengthSet = true;
                }

                return length;
            },

            nameLengthWithCalc() {
                let length = this.nameLength;

                if (this.isLarge && ! this.isSmartphonePortrait() && length) {
                    length -= 40;

                    if (this.isSmartphoneLandscape()) {
                        length -= 25;
                    }

                    if (this.isSmallLaptopScreen()) {
                        length += 5;
                    }

                    if (this.isLgBreakpoint() && ! this.isLargeLaptopScreen()) {
                        length += 27;
                    } else if (this.isLgBreakpoint() && this.isLargeLaptopScreen()) {
                        length += 9;
                    }
                }

                return length;
            },

            name() {
                let name = this.wish.name;

                if (this.onDashboard) {
                    return name;
                }

                if (this.wish.apiName && this.wish.apiName !== '' && this.wish.apiName !== null && this.wish.apiName !== this.wish.name) {
                    name += ' (' + this.wish.apiName + ')';
                }

                return name;
            },

            truncatedName() {
                let length = this.nameLengthWithCalc;

                if (length) {
                    return prune(this.name, length);
                }

                return this.name;
            },
        },
    };
</script>
